import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FormSection as ContainerFormSection } from '~/components/src/Containers';
import {
  ReduxFormInputField,
  ReduxFormSelectField,
  ReduxFormSensitiveField,
} from '~/components/src/Form/Fields/ReduxFormFields';
import { isRequired, useAPI } from '~/common';
import FieldWithEditMode from '~/profiles/components/FieldWithEditMode';
import Spin from '~/components/src/Spin';
import FormHeader from '../formHeader/FormHeader';
import constants from '../../constants';
import { TAirshipTagsProps } from './types';
import { fetchBaseUrls, fetchPayloadTypes } from '../airship/dataService';
import { TPayloadType } from '../airship/types';

const AirshipTagsForm = ({ t, values, partner, canUpdate, mode, touch, change }: TAirshipTagsProps) => {
  const partnerNumber = values.partnerNumber || partner.partnerNumber;
  const { data: baseUrls = [], isLoading: isBaseUrlsLoading } = useAPI(() => fetchBaseUrls(), []);
  const { data: payloadTypes = [], isLoading: isPayloadsLoading } = useAPI(() => fetchPayloadTypes(), []);

  if (isBaseUrlsLoading || isPayloadsLoading || !payloadTypes || !baseUrls) {
    return <Spin />;
  }

  const isPartnerNumberFieldVisible =
    mode === constants.modes.CREATE_SERVER &&
    values.payloadType &&
    payloadTypes?.length > 0 && // Makes sure payloadTypes are loaded before accessing them
    payloadTypes?.find(payloadType => payloadType.value === values.payloadType)?.allowsPartnerNumberOverride;

  return (
    <FormSection name={constants.partnerTypes.AIRSHIP_TAGS}>
      <FormHeader
        t={t}
        partner={partner}
        title={values.name}
        description={t('form.notification.storeMappingMessage')}
        partnerNumber={partnerNumber}
      />
      <ContainerFormSection>
        <div className="flex flex-col gap-2">
          <Field
            label={t('form.formFields.baseURL')}
            infoTooltip={t('form.formFields.tooltip.baseURL')}
            name="baseUrl"
            component={ReduxFormSelectField}
            options={baseUrls}
            validate={isRequired}
            touch={touch}
          />
          <FieldWithEditMode
            label={t('form.formFields.appKey')}
            infoTooltip={t('form.formFields.tooltip.appKey')}
            name="appKey"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            validate={isRequired}
            type="text"
          />
          <FieldWithEditMode
            label={t('form.formFields.token')}
            infoTooltip={t('form.formFields.tooltip.accessToken')}
            name="token"
            component={ReduxFormSensitiveField}
            placeholder={t('form.formFields.inputPlaceHolder')}
            isNeedActivateEditMode={canUpdate}
            type="text"
          />
          <Field
            label={t('form.formFields.tagGroup')}
            infoTooltip={t('form.formFields.tooltip.tagGroup')}
            name="tagGroup"
            component={ReduxFormInputField}
            validate={isRequired}
            type="text"
          />
          <Field
            label={t('form.formFields.payloadType')}
            name="payloadType"
            component={ReduxFormSelectField}
            options={payloadTypes}
            validate={isRequired}
            disabled={canUpdate}
            touch={touch}
            onChange={(e, value) => {
              const payloadType = payloadTypes?.find((type: TPayloadType) => type.value === value);
              change('AIRSHIP_TAGS.partnerNumber', payloadType?.partnerNumber);
            }}
          />
          {isPartnerNumberFieldVisible && (
            <Field
              label={t('form.formFields.partnerNumber')}
              name="partnerNumber"
              component={ReduxFormInputField}
              placeholder={t('form.formFields.inputPlaceHolder')}
              validate={isRequired}
              type="text"
              disabled={canUpdate}
            />
          )}
        </div>
      </ContainerFormSection>
    </FormSection>
  );
};

export default AirshipTagsForm;
