import React from 'react';
import PropTypes from 'prop-types';
import RulesList, { RuleCardTypes } from '~/components/src/RuleList';
import OverlayPageContainer from '~/customer/components/OverlayPageContainer';
import { MediumWrapper } from '~/components/src/Containers';
import Heading from '~/components/src/Heading';
import BtnIcon from '~/components/src/BtnIcon';
import './styles.scss';

const ViewTrigger = ({
  triggerTitle,
  triggerCriteria,
  t,
  goToEditScreenForThisTrigger,
  handleDecline,
  type,
  canEdit,
}) => {
  const isJourneyCondition = type === 'goal' || type === 'exitCondition';

  return (
    <OverlayPageContainer>
      <MediumWrapper>
        <Heading
          title={triggerTitle}
          crumbs={[
            {
              title: t('journey:back'),
              onClick: () => {
                handleDecline();
              },
            },
          ]}
        >
          {canEdit && (
            <BtnIcon
              testHook="viewTriggerActions"
              color="blue"
              onClick={goToEditScreenForThisTrigger}
              tooltip={t('common:actions.edit')}
              icon="edit"
            />
          )}
        </Heading>
        <div>
          <RulesList
            criteria={triggerCriteria}
            ruleCardType={RuleCardTypes.ViewCard}
            className="ViewTrigger-rulesList"
            isJourneyCondition={isJourneyCondition}
          />
        </div>
      </MediumWrapper>
    </OverlayPageContainer>
  );
};

ViewTrigger.propTypes = {
  triggerTitle: PropTypes.string.isRequired,
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
  goToEditScreenForThisTrigger: PropTypes.func.isRequired,
  handleDecline: PropTypes.func.isRequired,
  triggerCriteria: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([
          'EngagementRule',
          'ConversionRule',
          'CustomRule',
          'AIFactRule',
          'ExperimentRule',
          'VisitRule',
          'AudienceRule',
          'ReusableSelectionRule',
          'FixedTimeRule',
          'DynamicWaitTimeRule',
          'JourneyOverlapRule',
        ]),
        ruleType: PropTypes.shape({
          name: PropTypes.string,
          label: PropTypes.string.isRequired,
        }).isRequired,
        ruleId: PropTypes.string.isRequired,
        negation: PropTypes.bool,
        timeCondition: PropTypes.string,
        waitTime: PropTypes.string,
        title: PropTypes.string.isRequired,
        properties: PropTypes.arrayOf(
          PropTypes.shape({
            property: PropTypes.string.isRequired,
            constraint: PropTypes.string.isRequired,
            unique: PropTypes.number.isRequired,
            filter: PropTypes.string.isRequired,
            storedVariable: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
          }),
        ),
        subtitle: PropTypes.string,
      }),
    ),
  ),
};

export default ViewTrigger;
