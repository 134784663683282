import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAPI } from '~/common/ApiHooks';
import withSpinner from '~/components/src/Spinner/withSpinner';

import { transformCriteria } from '~/profiles/audiences/selectors';
import { addNamesFromRuleDefinitionsToPath } from '~/profiles/audiences/audienceUtils.js';
import { getRulesTreeFromTypes } from '~/common/modules/rules/selectors';

import * as RulesService from '~/common/modules/rules/dataService';
import * as TriggersService from '~/customer/triggers/dataService';
import { useQuery } from '~/customer/components/utils';
import { withRouter } from '~/common/withRouter';

import { removeItemsNotAvailableInOrchestration } from './utils';
import EditTrigger from './presenter.js';
import { NODE_TYPES, RULE_GROUP_TYPES } from './constants';

const getType = (isStartTrigger, type) => {
  switch (type) {
    case NODE_TYPES.GOAL:
      return RULE_GROUP_TYPES.GOAL;
    case NODE_TYPES.EXIT_CONDITION:
      return RULE_GROUP_TYPES.EXIT_BY_CONDITION;
    default:
      return isStartTrigger ? RULE_GROUP_TYPES.START_TRIGGER : RULE_GROUP_TYPES.TRIGGER;
  }
};

const fetchRuleTypes = ruleGroup => RulesService.getRulesDefinitions(ruleGroup);

const fetchAllVariables = () => RulesService.getAllRuleVariables();

const fetchTriggerIfEdit = triggerId => {
  const isEdit = !!triggerId;

  if (isEdit) {
    return TriggersService.fetchTrigger(triggerId);
  }

  return Promise.resolve(null);
};

const fetchRuleTypesAndTrigger = (ruleGroup, triggerId, excludeRules = {}) =>
  Promise.all([fetchRuleTypes(ruleGroup), fetchTriggerIfEdit(triggerId)]).then(([ruleDefinitions, trigger]) => {
    let transformedTrigger = null;
    if (trigger) {
      transformedTrigger = addNamesFromRuleDefinitionsToPath(ruleDefinitions, 'criteria')(trigger);
      transformedTrigger = {
        ...transformedTrigger,
        criteria: transformCriteria(transformedTrigger.criteria),
      };
    }
    return [
      removeItemsNotAvailableInOrchestration(getRulesTreeFromTypes(ruleDefinitions, excludeRules)),
      transformedTrigger,
    ];
  });

const useAllVariables = () => {
  const [allVariables, setAllVariables] = useState(null);

  const { data, isLoading, error } = useAPI(() => fetchAllVariables());

  useEffect(() => {
    if (data !== null) {
      setAllVariables(data);
    }
  }, [data]);

  const refetchAllVariables = async () => {
    const refetchedAllVariables = await fetchAllVariables();
    setAllVariables(refetchedAllVariables);
  };

  return {
    allVariables,
    isLoading: isLoading || allVariables === null,
    error,
    refetchAllVariables,
  };
};

const withDataFetching = WrappedComponent => props => {
  const { triggerId } = useParams();
  const query = useQuery();
  const parentStepId = query.get('parentStepId');
  const isJourneyCondition = props.type === NODE_TYPES.GOAL || props.type === NODE_TYPES.EXIT_CONDITION;

  const isStartTrigger =
    (!triggerId && !parentStepId && !isJourneyCondition) || props.journeyContents?.nodes?.nodeId === triggerId;

  const ruleGroup = getType(isStartTrigger, props.type);

  const excludeRules = {};

  const ruleTypesAndTriggerRequest = useAPI(() => fetchRuleTypesAndTrigger(ruleGroup, triggerId, excludeRules));
  const allVariablesRequest = useAllVariables();

  const ruleTypesTree = ruleTypesAndTriggerRequest.data?.[0];
  const trigger = ruleTypesAndTriggerRequest.data?.[1];
  const { allVariables, refetchAllVariables } = allVariablesRequest;

  return (
    <WrappedComponent
      isLoading={ruleTypesAndTriggerRequest.isLoading || allVariablesRequest.isLoading}
      error={ruleTypesAndTriggerRequest.error || allVariablesRequest.error}
      ruleTypesTree={ruleTypesTree}
      allVariables={allVariables}
      trigger={trigger}
      refetchAllVariables={refetchAllVariables}
      isJourneyCondition={isJourneyCondition}
      {...props}
    />
  );
};

const enhance = compose(
  withRouter,
  translate('orchestration'),
  withDataFetching,
  withSpinner('blankOverlay--lightGray'),
);

export default enhance(EditTrigger);
