import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Notification } from '~/components';
import Form from '~/components/src/Form/Form';
import FormActions from '~/components/src/Form/ActionsDefault';
import Heading from '~/components/src/Heading';
import { changeUrl } from '~/common/history';
import Page from '~/components/src/Page';
import { Panel, BaseFormSection, MediumWrapper } from '~/components/src/Containers';
import Spinner from '~/components/src/Spinner';
import CardMessage from '~/components/src/CardMessage';
import { getConnectorListPath } from '~/profiles/connectors/containers/commonActions';
import { SERVER_CONNECTOR_CATEGORY, EVENT_CONNECTOR_CATEGORY } from '~/profiles/connectors/types';
import { getAngularService } from 'ReactAngular/react.service';
import ClangFormContainer from './partners/clang/ClangFormContainer';
import constants from './constants';
import DoubleClickContainer from './partners/doubleClick/DoubleClickContainer';
import AmnetForm from './partners/amnet/AmnetForm';
import ExactTargetForm from './partners/exactTarget/ExactTargetForm';
import FacebookContainer from './partners/facebook/FacebookContainer';
import TurnForm from './partners/turn/TurnForm';
import SelligentForm from './partners/selligent/SelligentForm';
import MediaMathForm from './partners/mediaMath/MediaMathForm';
import MobProForm from './partners/mobPro/MobProForm';
import KinesisForm from './partners/kinesis/KinesisForm';
import GooglePubSubForm from './partners/googlePubSub/GooglePubSubForm';
import AdobeForm from './partners/adobe/AdobeForm';
import ClickDistrictForm from './partners/clickDistrict/ClickDistrictForm';
import AdForm from './partners/adform/AdForm';
import OracleResponsys from './partners/oracleResponsys/OracleResponsys';
import OptimizelyForm from './partners/optimizely/OptimizelyForm';
import Relay42ApiForm from './partners/relay42Api/Relay42ApiForm';
import GoogleAdsContainer from './partners/googleAds/GoogleAdsContainer';
import DoubleClickOfflineContainer from './partners/doubleClickOffline/DoubleClickOfflineContainer';
import TripolisContainer from './partners/tripolis/TripolisContainer';
import AppNexusContainer from './partners/appNexus/AppNexusContainer';
import AdnuntiusForm from './partners/adnuntius/AdnuntiusForm';
import CustomForm from './partners/custom/CustomForm';
import MailChimpForm from './partners/mailChimp/MailChimpForm';
import SalesforceForm from './partners/salesforce/SalesforceForm';
import TradeDeskContainer from './partners/tradeDesk/TradeDeskContainer';
import AzureEventHubForm from './partners/azureEventHub/AzureEventHubForm';
import TwitterForm from './partners/twitter/TwitterForm';
import MarketoForm from './partners/marketo/MarketoForm';
import AirshipForm from './partners/airship/AirshipForm';
import AirshipTagsForm from './partners/airshipTags/AirshipTagsForm';
import FacebookConversionsForm from './partners/facebookConversions/components/FacebookConversionsForm';
import LinkedFilters from './LinkedFilters';
import DIYForm from './partners/diy/DIYForm';
import LinkedTransformations from './LinkedTransformations';
import AmazonDspForm from './partners/amazonDsp/AmazonDspForm';
import YahooForm from './partners/yahoo/YahooForm';
import LinkedinForm from './partners/linkedin/LinkedinForm';
import GoogleCustomerMatchForm from './partners/googleCustomerMatch/GoogleCustomerMatchForm';
import './styles.scss';
import { ConnectorModeContext } from './connectorModeContext';

const { CREATE_EVENT, EDIT_SERVER, EDIT_EVENT } = constants.modes;

const getGoBackLink = (mode, t) => {
  const linksByMode = {
    CREATE_SERVER: { path: getConnectorListPath(SERVER_CONNECTOR_CATEGORY), title: t('backToList') },
    CREATE_EVENT: { path: getConnectorListPath(EVENT_CONNECTOR_CATEGORY), title: t('backToList') },
    EDIT_SERVER: { path: getConnectorListPath(SERVER_CONNECTOR_CATEGORY), title: t('backToList') },
    EDIT_EVENT: { path: getConnectorListPath(EVENT_CONNECTOR_CATEGORY), title: t('backToList') },
  };

  return linksByMode[mode];
};

class ConnectorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSupportEngineer: false,
      isSubmitted: false,
      activeSidePanel: null,
    };
  }

  componentDidMount() {
    const { fetchDetails } = this.props;
    fetchDetails();

    const SecurityService = getAngularService(document, 'SecurityService');
    SecurityService.getSecurityContext()
      .then(context => {
        this.setState({
          isSupportEngineer: context.isSupportEngineer(),
        });
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    this.props.clearConnectorForm();
  }

  render() {
    const { t, handleSubmit, submitting, formValues, isValid, change, partner, connectors, canUpdate, mode, touch } =
      this.props;

    const isSubmitButtonDisabled = () => {
      if (partner.partnerType === constants.partnerTypes.CUSTOM && !this.state.isSupportEngineer) {
        return true;
      }

      return false;
    };

    const goBackLink = getGoBackLink(mode, t);
    const shouldShowLinkedFilters =
      partner.partnerType &&
      partner.partnerType !== constants.partnerTypes.FACEBOOK_CONVERSIONS &&
      partner.partnerType !== constants.partnerTypes.FACEBOOK &&
      [CREATE_EVENT, EDIT_EVENT].includes(mode);

    const renderForm = () => {
      const commonProps = {
        t,
        values: formValues,
        change,
        partner,
        canUpdate,
        mode,
        touch,
      };
      switch (partner.partnerType) {
        case constants.partnerTypes.CLANG:
          return <ClangFormContainer {...commonProps} connectors={connectors} />;
        case constants.partnerTypes.CLANG_BATCH:
          return <ClangFormContainer {...commonProps} isBatch={true} connectors={connectors} />;
        case constants.partnerTypes.DOUBLE_CLICK:
          return <DoubleClickContainer {...commonProps} isSubmitted={this.state.isSubmitted} />;
        case constants.partnerTypes.AMNET:
          return <AmnetForm {...commonProps} />;
        case constants.partnerTypes.EXACT_TARGET:
          return <ExactTargetForm {...commonProps} connectors={connectors} />;
        case constants.partnerTypes.FACEBOOK:
          return <FacebookContainer {...commonProps} />;
        case constants.partnerTypes.TURN:
          return <TurnForm {...commonProps} />;
        case constants.partnerTypes.SELLIGENT:
          return <SelligentForm {...commonProps} />;
        case constants.partnerTypes.MEDIA_MATH:
          return <MediaMathForm {...commonProps} />;
        case constants.partnerTypes.MOB_PRO:
          return <MobProForm {...commonProps} />;
        case constants.partnerTypes.CLICK_DISTRICT:
          return <ClickDistrictForm {...commonProps} />;
        case constants.partnerTypes.ADFORM:
          return <AdForm {...commonProps} />;
        case constants.partnerTypes.OPTIMIZELY:
          return <OptimizelyForm {...commonProps} />;
        case constants.partnerTypes.RELAY42_API:
          return <Relay42ApiForm {...commonProps} />;
        case constants.partnerTypes.GOOGLE_ADS:
          return <GoogleAdsContainer {...commonProps} />;
        case constants.partnerTypes.DOUBLE_CLICK_OFFLINE:
          return <DoubleClickOfflineContainer {...commonProps} />;
        case constants.partnerTypes.TRIPOLIS:
          return <TripolisContainer {...commonProps} connectors={connectors} />;
        case constants.partnerTypes.APPNEXUS:
          return <AppNexusContainer {...commonProps} />;
        case constants.partnerTypes.ADNUNTIUS:
          return <AdnuntiusForm {...commonProps} />;
        case constants.partnerTypes.CUSTOM:
          return <CustomForm {...commonProps} isSupportEngineer={this.state.isSupportEngineer} />;
        case constants.partnerTypes.KINESIS:
          return <KinesisForm {...commonProps} />;
        case constants.partnerTypes.GOOGLE_PUBSUB:
          return <GooglePubSubForm {...commonProps} />;
        case constants.partnerTypes.MAILCHIMP:
          return <MailChimpForm {...commonProps} />;
        case constants.partnerTypes.ADOBE:
          return <AdobeForm {...commonProps} />;
        case constants.partnerTypes.SALESFORCE:
          return <SalesforceForm {...commonProps} />;
        case constants.partnerTypes.TRADEDESK:
          return <TradeDeskContainer {...commonProps} />;
        case constants.partnerTypes.TWITTER:
          return <TwitterForm {...commonProps} />;
        case constants.partnerTypes.AZURE_EVENTHUB:
          return <AzureEventHubForm {...commonProps} />;
        case constants.partnerTypes.MARKETO:
          return <MarketoForm {...commonProps} />;
        case constants.partnerTypes.AIRSHIP:
          return <AirshipForm {...commonProps} />;
        case constants.partnerTypes.AIRSHIP_TAGS:
          return <AirshipTagsForm {...commonProps} />;
        case constants.partnerTypes.FACEBOOK_CONVERSIONS:
          return <FacebookConversionsForm {...commonProps} />;
        case constants.partnerTypes.ORACLE_RESPONSYS:
          return <OracleResponsys {...commonProps} />;
        case constants.partnerTypes.DIY:
          return <DIYForm {...commonProps} />;
        case constants.partnerTypes.AMAZON_DSP:
          return <AmazonDspForm {...commonProps} />;
        case constants.partnerTypes.YAHOO:
          return <YahooForm {...commonProps} />;
        case constants.partnerTypes.LINKEDIN:
          return <LinkedinForm {...commonProps} />;
        case constants.partnerTypes.GOOGLE_CUSTOMER_MATCH:
          return <GoogleCustomerMatchForm {...commonProps} />;
        default:
          return <Spinner />;
      }
    };

    const setActiveSidePanel = panel => {
      this.setState({ activeSidePanel: panel });
    };

    const isEdit = mode === EDIT_EVENT || mode === EDIT_SERVER;

    return (
      <Page>
        <MediumWrapper xlOffset={this.state.activeSidePanel ? 1 : 2}>
          <Heading
            title={`${canUpdate ? t('form.editHeader') : t('form.header')}`}
            crumbs={[
              {
                title: goBackLink.title,
                onClick: () => {
                  changeUrl(goBackLink.path);
                },
              },
            ]}
          />

          <Panel>
            <Form onSubmit={handleSubmit} isSubmitting={submitting}>
              <div>
                <ConnectorModeContext.Provider value={{ mode }}>{renderForm()}</ConnectorModeContext.Provider>
              </div>
              {this.state.isSubmitted && !isValid && (
                <BaseFormSection className="ConnectorForm-validationMessage">
                  <CardMessage message={t('form.isNotValid')} />
                </BaseFormSection>
              )}

              {shouldShowLinkedFilters && (
                <>
                  <Notification kind="warning">{t('form.filtersTransformationsWarningNote')}</Notification>
                  <LinkedFilters
                    deleteLinkedFilter={filterId => {
                      change(
                        `${partner.partnerType}.filters`,
                        formValues.filters.filter(filter => filter.filterId !== filterId),
                      );
                    }}
                    linkedFilters={formValues.filters || []}
                    setLinkedFilters={linkedFilters => {
                      change(`${partner.partnerType}.filters`, linkedFilters);
                    }}
                    setActiveSidePanel={setActiveSidePanel}
                    activeSidePanel={this.state.activeSidePanel}
                  />
                  <div className="py-3" />

                  <LinkedTransformations
                    deleteLinkedTransformation={transformationId => {
                      change(
                        `${partner.partnerType}.transformations`,
                        formValues.transformations.filter(
                          transformation => transformation.transformationId !== transformationId,
                        ),
                      );
                    }}
                    linkedTransformations={formValues.transformations || []}
                    setLinkedTransformations={linkedTransformations => {
                      change(`${partner.partnerType}.transformations`, linkedTransformations);
                    }}
                    setActiveSidePanel={setActiveSidePanel}
                    activeSidePanel={this.state.activeSidePanel}
                  />
                </>
              )}

              <FormActions
                t={t}
                isDisableSave={isSubmitButtonDisabled()}
                submitting={submitting}
                onConfirm={() => this.setState({ isSubmitted: true })}
                submitText={isEdit ? t('form.update') : t('form.create')}
                onCancel={() => {
                  changeUrl(goBackLink.path);
                }}
              />
            </Form>
          </Panel>
        </MediumWrapper>
      </Page>
    );
  }
}

ConnectorForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  connectors: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  clearConnectorForm: PropTypes.func.isRequired,
  fetchDetails: PropTypes.func,
  partnerTypes: PropTypes.array,
  partner: PropTypes.object,
};

ConnectorForm.defaultProps = {
  partner: {},
};

export default ConnectorForm;
