import Api, { API_BASE, parseResponse } from '~/common/ApiService';

const BASE_PATH = `${API_BASE}/v1/partners`;

const getTripolisContactGroupsURL = partnerId => `${BASE_PATH}/tripolis/${partnerId}/contactGroups`;

const getContactGroupsOptions = partnerId =>
  Api.callGet(getTripolisContactGroupsURL(partnerId), {
    shouldShowToast: false,
    shouldHandleCommonErrors: false,
  }).then(parseResponse);

export { getContactGroupsOptions };
