export const NODE_TYPES = {
  GOAL: 'goal',
  EXIT_CONDITION: 'exitCondition',
  TRIGGER: 'trigger',
};

export const RULE_GROUP_TYPES = {
  START_TRIGGER: 'START_TRIGGER',
  TRIGGER: 'TRIGGER',
  GOAL: 'GOAL',
  EXIT_BY_CONDITION: 'EXIT_BY_CONDITION',
};
