import { get } from 'lodash';
import { getAssetsPrefix } from '~/common/SecurityMetaService';
import { buildAssetsUrl } from '~/common/history';
import i18n from '../i18n';

export const getDetails = (connector = {}) => connector.partnerDetails || {};
export const getType = connector => getDetails(connector).partnerType || '';

const i18nLabel = key => i18n.t(`partners:card.labels.${key}`);

const makeProperty = (name, value) => ({
  name,
  value,
});

const variablesToProps = variables =>
  variables ? Object.keys(variables).map(name => makeProperty(name, variables[name].value)) : [];

const getPropertiesFromPixelPartner = connector => {
  const DATA_LABEL = 'data-label';
  const DATA_MODEL = 'data-ng-model';
  const templateView = connector.variant && connector.variant.templateView;

  if (!templateView) return [];

  const extractValueFromAngularAttrs = (dataAttr, str) => {
    const regExp = new RegExp(`${dataAttr}="(.*?)"`);
    const match = str.match(regExp) || [];
    return match[1] || '';
  };

  const getName = str => extractValueFromAngularAttrs(DATA_LABEL, str);
  const getValue = str => {
    const pathToVariable = extractValueFromAngularAttrs(DATA_MODEL, str).replace(
      'tag.content',
      'partnerDetails.configuration',
    );
    return get(connector, `${pathToVariable}.value`, '');
  };

  return templateView
    .split('<r42property-type')
    .filter(str => str.includes(DATA_LABEL) && str.includes(DATA_MODEL))
    .map(str => makeProperty(getName(str), getValue(str)));
};

export const getPropertiesFromConnector = connector => {
  const details = getDetails(connector);
  const type = getType(connector);

  const pr = (key, value) => makeProperty(i18nLabel(key), value);
  const c = connector;
  const d = details;

  // d = details
  const map = {
    ADFORM: () => [pr('adform', d.segment.label)],
    AMNET: () => [pr('amnetId', d.amnetClientId), pr('amnetKey', d.amnetSegmentKey)],
    APPNEXUS: () => [pr('appnexus', d.segment.name)],
    CLANG: () => [pr('clang', d.clangCampaign.name)],
    CLANG_BATCH: () => [pr('clang_batch', d.segmentName)],
    CLICK_DISTRICT: () => [pr('click_district', d.clickDistrictId)],
    CUSTOM: () => [pr('custom', d.partnerAudienceId), ...variablesToProps(d.data || {})],
    DOUBLE_CLICK_OFFLINE: () => [
      pr('double_click_offline', d.floodlightActivity.name),
      ...variablesToProps(d.variables),
      pr('double_click_offline_conversion', d.conversionVariableName ? d.conversionVariableName.value : ''),
    ],
    DOUBLE_CLICK: () => [pr('double_click', d.userList.name ? d.userList.name : d.userList.userListId)],
    FACEBOOK: () => [pr('facebook', d.audience && d.audience.name)],
    KINESIS: () => [pr('kinesis', d.sourceAliasName), ...variablesToProps(d.data || {})],
    GOOGLE_PUBSUB: () => [pr('google_pubsub', d.sourceAliasName), ...variablesToProps(d.data || {})],
    GOOGLE_ADS: () => [pr('googleAds', d.userList.name)],
    AZURE_EVENTHUB: () => [pr('azure_eventhub', d.sourceAliasName), ...variablesToProps(d.data || {})],
    MAILCHIMP: () => [pr('mailchimp', d.mailChimpList ? d.mailChimpList.name : '')],
    MEDIA_MATH: () => [pr('media_math', d.mediaMathId)],
    MOB_PRO: () => [pr('mob_proType', d.eventType), pr('mob_proParameter', d.eventParameter)],
    OPTIMIZELY: () => [
      pr('optimizelyProject', d.project ? d.project.name : 'Unknown'),
      pr('optimizelyDCP', d.dcpDatasource.name),
    ],
    PIXEL_PARTNER: () => [...getPropertiesFromPixelPartner(c)],
    SALESFORCE: () => [pr('salesforce', d.salesforceAudience && d.salesforceAudience.name)],
    SELLIGENT: () => [pr('selligent', d.gateName)],
    TRADEDESK: () => [pr('tradeDesk', d.audience)],
    TRIPOLIS: () => [pr('tripolis', d.contactGroup.label)],
    TWITTER: () => [pr('twitterAudience', d.audience.name)],
    TURN: () => [pr('turnId', d.turnClientId), pr('turnKey', d.turnSegmentKey)],
    MARKETO: () => [pr('marketo', d?.smartCampaign?.name), ...variablesToProps(d.variables)],
    AIRSHIP: () => [pr('eventName', d?.eventName)],
    AIRSHIP_TAGS: () => [
      pr('airshipTagsTagGroup', d?.tagGroup || i18n.t('partners:card.labels.connectorDefault')),
      pr('airshipTagsTagName', d?.tagName),
    ],
    ORACLE_RESPONSYS: () => [pr('eventName', d?.eventName)],
    AMAZON_DSP: () => [
      pr('amazonDspId', d?.audience?.id),
      pr('amazonDspName', d?.audience?.name),
      pr('amazonDspDescription', d?.audience?.description),
    ],
    YAHOO: () => [pr('taxonomy', d?.name || d?.id)],
    LINKEDIN: () => [pr('linkedinAudience', d?.audienceName || d?.audienceId)],
    GOOGLE_CUSTOMER_MATCH: () => [pr('googleCustomerMatchUserId', d?.userListName || d?.userListId)],
    default: () => [],
  };

  const getProperties = map[type] || map.default;
  return getProperties();
};

const imgUrl = () => `${getAssetsPrefix()}/img`;

export const getLogoUrlByConnectorType = type => `${imgUrl()}/partners/${type}.png`;

export const connectorPlaceholder = () => `${imgUrl()}/connectorPlaceholders/image-file-warning.svg`;

export const getLogoUrl = connector => {
  const type = getType(connector);

  if (type) {
    return getLogoUrlByConnectorType(type);
  }

  const logo = get(connector, 'variant.logo');

  return logo ? `${imgUrl()}/templates/${logo}` : `${imgUrl()}/no-image.png`;
};

export const getConnectorImage = siteId => connector => {
  if (connector.partnerType !== 'CUSTOM') {
    return buildAssetsUrl(`img/partners/${connector.partnerType}.png`);
  }
  return `/api/v1/partners/custom/logo/${siteId}/${connector.partnerId}`;
};
