import React from 'react';
import { flow } from 'lodash';
import PropTypes from 'prop-types';
import { Trans, translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { buildUrl } from '~/common';
import ConnectorPicker from '~/common/modules/connectors/components/ConnectorPicker';
import { getAssetsPrefix } from '~/common/SecurityMetaService';
import { ConnectorCardWrapper, INTEGRATION_TYPE } from '~/components/src/ConnectorCard';
import Notification from '~/components/src/Notification';
import i18n from '~/i18n';
import { getLogoUrlByConnectorType } from '~/profiles/connectors';
import Adform from './ConnectorContent/Adform';
import Airship from './ConnectorContent/Airship';
import AirshipTags from './ConnectorContent/AirshipTags';
import AmazonDsp from './ConnectorContent/AmazonDsp';
import { Amnet } from './ConnectorContent/Amnet';
import AppNexus from './ConnectorContent/AppNexus/index';
import Clang from './ConnectorContent/Clang';
import ClangBatch from './ConnectorContent/ClangBatch';
import { Custom } from './ConnectorContent/Custom';
import DoubleClick from './ConnectorContent/DoubleClick';
import DoubleClickOffline from './ConnectorContent/DoubleClickOffline';
import Facebook from './ConnectorContent/Facebook/index';
import GoogleAds from './ConnectorContent/GoogleAds/container';
import LinkedIn from './ConnectorContent/LinkedIn';
import MailChimp from './ConnectorContent/MailChimp';
import Marketo from './ConnectorContent/Marketo';
import { Mediamath } from './ConnectorContent/Mediamath';
import Mobpro from './ConnectorContent/Mobpro/index';
import Optimizely from './ConnectorContent/Optimizely';
import OracleResponsys from './ConnectorContent/OracleResponsys';
import { Platform161 } from './ConnectorContent/Platform161';
import Salesforce from './ConnectorContent/Salesforce';
import Selligent from './ConnectorContent/Selligent';
import Streams from './ConnectorContent/Streams';
import { TradeDesk } from './ConnectorContent/TradeDesk/TradeDesk';
import Tripolis from './ConnectorContent/Tripolis/index';
import { Turn } from './ConnectorContent/Turn';
import Twitter from './ConnectorContent/Twitter';
import Yahoo from './ConnectorContent/Yahoo';
import GoogleCustomerMatch from './ConnectorContent/GoogleCustomerMatch';
import { makeIntegrationAddHandler, makeIntegrationChangeHandler, makeIntegrationDeleteHandler } from './utils';

// Put null for parameterless connectors
const CONNECTOR_MAP = {
  ADFORM: Adform,
  ADOBE: null,
  ADNUNTIUS: null,
  AMNET: Amnet,
  APPNEXUS: AppNexus,
  CLANG: Clang,
  CLANG_BATCH: ClangBatch,
  CLICK_DISTRICT: Platform161,
  CUSTOM: Custom,
  DOUBLE_CLICK: DoubleClick,
  DOUBLE_CLICK_OFFLINE: DoubleClickOffline,
  EXACT_TARGET: null,
  FACEBOOK: Facebook,
  GOOGLE_PUBSUB: Streams,
  GOOGLE_ADS: GoogleAds,
  KINESIS: Streams,
  AZURE_EVENTHUB: Streams,
  MAILCHIMP: MailChimp,
  MEDIA_MATH: Mediamath,
  MOB_PRO: Mobpro,
  OPTIMIZELY: Optimizely,
  RELAY42_API: null,
  SALESFORCE: Salesforce,
  SELLIGENT: Selligent,
  TRADEDESK: TradeDesk,
  TRIPOLIS: Tripolis,
  TURN: Turn,
  TWITTER: Twitter,
  MARKETO: Marketo,
  AIRSHIP: Airship,
  AIRSHIP_TAGS: AirshipTags,
  ORACLE_RESPONSYS: OracleResponsys,
  AMAZON_DSP: AmazonDsp,
  YAHOO: Yahoo,
  LINKEDIN: LinkedIn,
  GOOGLE_CUSTOMER_MATCH: GoogleCustomerMatch,

  [INTEGRATION_TYPE.r42]: null, // default pseudo-connector
};

const getDescriptionByPartnerName = (partnerName, t) => {
  const descriptionsByPartnerName = {
    DOUBLE_CLICK_OFFLINE: t('audiences:connectors.doubleClickOffline.info'),
    MOB_PRO: t('audiences:connectors.mobpro.info'),
  };

  return descriptionsByPartnerName[partnerName];
};

const ConnectorCard = ({
  onEdit,
  onDelete,
  connector,
  t,
  isSubmitted,
  stageName,
  usedVariables,
  supportsEventListeners,
  className,
  type,
}) => {
  const CardBody = CONNECTOR_MAP[connector.partnerDetails.partnerType];

  // null is valid, so coercion is not allowed
  if (CardBody === undefined) {
    // eslint-disable-next-line no-console
    console.error(
      'You tried to add connector which is not implemented on client side. Please check the EditableComponentList component and add the support of required connectors',
    );
  }

  const description = getDescriptionByPartnerName(connector.partnerDetails.partnerType, t);

  return (
    <ConnectorCardWrapper
      className={className}
      connector={connector}
      name={connector.partnerName}
      description={description}
      logoUrl={connector.image}
      onDelete={onDelete}
      supportsEventListeners={supportsEventListeners}
      key={`connector-card-${connector.partnerDetails.partnerId}`}
      testHook={`connector-card-${connector.partnerDetails.partnerId}`}
      t={t}
    >
      {CardBody && (
        <CardBody
          type={type}
          stageName={stageName}
          connector={connector}
          onEdit={onEdit}
          t={t}
          isSubmitted={isSubmitted}
          usedVariables={usedVariables}
        />
      )}
    </ConnectorCardWrapper>
  );
};

const EditableIntegrationsList = ({
  onChange,
  integrations,
  allConnectors,
  t,
  isSubmitted,
  stageName = '',
  usedVariables,
  isDefaultConnectorDisplayed = true,
  type = 'Audience',
}) => {
  if (!allConnectors) {
    return null;
  }
  const allSelectableConnectors = allConnectors.filter(connector =>
    Object.keys(CONNECTOR_MAP).includes(connector.partnerType),
  );

  if (!allSelectableConnectors.length) {
    const createConnectorPageUrl = buildUrl('profiles/partners/list');
    return (
      <Notification kind="warning" header="">
        <p className="EditableIntegrationsList-warningText">
          <Trans>
            You don't have any connectors yet. You can create one by{' '}
            <Link to={createConnectorPageUrl} className="EditableIntegrationsList-createConnectorLink">
              clicking here
            </Link>
            .
          </Trans>
        </p>
      </Notification>
    );
  }

  const onIntegrationAdd = flow(makeIntegrationAddHandler(integrations), onChange);
  const onIntegrationEdit = flow(makeIntegrationChangeHandler(integrations), onChange);
  const onIntegrationDelete = flow(makeIntegrationDeleteHandler(integrations), onChange);

  const disabledConnectorsIds = integrations.map(connector => connector.partnerDetails.partnerId);
  const connectorList = allSelectableConnectors.map(connector => {
    const listItem = {
      activated: connector.activated,
      image: connector.image || `${getAssetsPrefix()}/img/partners/${connector.partnerType}.png`,
      partnerId: connector.partnerId,
      partnerName: connector.partnerName,
      partnerNumber: connector.partnerNumber,
      partnerType: connector.partnerType,
      supportsEventListeners: connector.supportsEventListeners,
      testHook: connector.partnerType,
      title: connector.partnerName,
      value: connector.partnerId,
      key: `${connector.partnerType}_${connector.partnerId}}`,
    };

    listItem.isDisabled = disabledConnectorsIds.includes(connector.partnerId);

    if (!listItem.activated) {
      listItem.status = i18n.t('connectors:deactivated');
    }

    return listItem;
  });

  return (
    <div>
      {/* Default system pseudo-connector */}
      {isDefaultConnectorDisplayed && (
        <ConnectorCard
          className="DefaultPsuedoConnector"
          connector={{
            activated: true,
            image: getLogoUrlByConnectorType('RELAY42'),
            partnerName: t('list.defaultPsuedoConnector'),
            partnerDetails: {
              partnerType: INTEGRATION_TYPE.r42,
              partnerId: INTEGRATION_TYPE.r42,
            },
          }}
          t={t}
        />
      )}
      {integrations.map(connector => (
        <ConnectorCard
          connector={connector}
          onEdit={onIntegrationEdit}
          t={t}
          onDelete={onIntegrationDelete}
          key={`connector-card-${connector.partnerDetails.partnerId}`}
          stageName={stageName}
          isSubmitted={isSubmitted}
          usedVariables={usedVariables}
          supportsEventListeners={connector.supportsEventListeners}
          type={type}
        />
      ))}
      <ConnectorPicker listItems={connectorList} actionName={t('list.addConnector')} onSelect={onIntegrationAdd} />
    </div>
  );
};

const connectorType = PropTypes.exact({
  activated: PropTypes.bool,
  image: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  partnerType: PropTypes.string.isRequired,
  partnerNumber: PropTypes.number.isRequired,
  partnerName: PropTypes.string.isRequired,
  supportsEventListeners: PropTypes.bool.isRequired,
});

const variableType = PropTypes.exact({
  name: PropTypes.string.isRequired,
  variableId: PropTypes.string.isRequired,
});

const integrationType = PropTypes.exact({
  image: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  activated: PropTypes.bool.isRequired,
  supportsEventListeners: PropTypes.bool.isRequired,
  partnerName: PropTypes.string.isRequired,
  partnerDetails: PropTypes.shape({
    partnerType: PropTypes.string.isRequired,
    partnerNumber: PropTypes.number.isRequired,
    partnerId: PropTypes.string.isRequired,
    /* Integration form values specific to a certain partner type are also stored here in "partnerDetails" */
  }),
});

EditableIntegrationsList.propTypes = {
  allConnectors: PropTypes.arrayOf(connectorType),
  integrations: PropTypes.arrayOf(integrationType),
  usedVariables: PropTypes.arrayOf(variableType),
  isDefaultConnectorDisplayed: PropTypes.bool,
  isSubmitted: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default translate('connectors')(EditableIntegrationsList);
